export interface IPassportArchiveReport {
  FILTER_ARCHIVE: string;

  TABLE_INDICATORS: string;
  TABLE_STRING_CODE: string;
  TABLE_TOTAL_NUMBER: string;
  TABLE_START_DATE: string;
  TABLE_END_DATE: string;
  TABLE_EAD_SENDED_TO_INVENTORY: string;
  TABLE_EAD_SENDED_TO_INVENTORY_START_DATE: string;
  TABLE_EAD_SENDED_TO_INVENTORY_END_DATE: string;
  TABLE_STORED_AFTER_LIMIT: string;
  TABLE_TOTAL_IN_YEAR: string;
}

export const fields: IPassportArchiveReport = {
  FILTER_ARCHIVE: "archiveCode",

  TABLE_INDICATORS: "tmp2",
  TABLE_STRING_CODE: "tmp3",
  TABLE_TOTAL_NUMBER: "tmp4",
  TABLE_START_DATE: "tmp5",
  TABLE_END_DATE: "tmp6",
  TABLE_EAD_SENDED_TO_INVENTORY: "tmp7",
  TABLE_EAD_SENDED_TO_INVENTORY_START_DATE: "tmp8",
  TABLE_EAD_SENDED_TO_INVENTORY_END_DATE: "tmp9",
  TABLE_STORED_AFTER_LIMIT: "tmp10",
  TABLE_TOTAL_IN_YEAR: "tmp11",
};
