import { IReportElement } from "@monorepo/reports/src/views/PassportArchiveReportView/types/reportElement";
import { fields } from "@monorepo/utils/src/variables/projectsData/passportArchiveReport/fields";

type Keys = keyof typeof fields;
type Values = typeof fields[Keys];

export const convertApiItemToUi = (item: IReportElement): Record<Values, string | number> => ({
  [fields.TABLE_INDICATORS]: item.type || "",
  [fields.TABLE_STRING_CODE]: item.typeCode || "",
  [fields.TABLE_TOTAL_NUMBER]: item.eadCount || "",
  [fields.TABLE_START_DATE]: item.startDate || "",
  [fields.TABLE_END_DATE]: item.endDate || "",
  [fields.TABLE_TOTAL_IN_YEAR]: item.eadInYear || "",
  [fields.TABLE_STORED_AFTER_LIMIT]: item.overtime || "",
});
