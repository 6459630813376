import { Module } from "vuex";
import { IRootState } from "@monorepo/reports/src/store";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { encodeQueryData, getFullPath, getQuery } from "@monorepo/utils/src/api/utils";
import { convertFiltersCustomToApi } from "@monorepo/reports/src/views/PassportArchiveReportView/utils/convertFiltersToApi";
import { ITableFiltersObject } from "@monorepo/utils/src/store/types/tableFiltersObject";
import { convertFiltersToApi, IConvertFiltersToApiResponse } from "@monorepo/utils/src/api/convertFiltersToApi";
import { IReportElement } from "@monorepo/reports/src/views/PassportArchiveReportView/types/reportElement";
import { ISection, Sections } from "@monorepo/utils/src/types/cellsSections";
import { actions } from "@monorepo/utils/src/store/modules/actions";
import { getters } from "@monorepo/utils/src/store/modules/getters";
import { mutations } from "@monorepo/utils/src/store/modules/mutations";
import { ISearchTemplate } from "@monorepo/utils/src/types/ISearchTemplate";
import { IAutocompleteElement } from "@monorepo/utils/src/types/oikAutocompleteType";

interface ITableState {
  filters: Partial<ITableFiltersObject>;
  totalLength: number;
  section?: Sections;
  infiniteId: string | null;
  sectionCells: ISection;
  isOpenFilters: boolean;
  data: IReportElement[];
  searchTemplates: ISearchTemplate[];
  cells: string[];
  libraries: Record<string, never>;
  isTableLoading: boolean;
}

const defaultFilters = (): Partial<ITableFiltersObject> => ({
  fieldFilters: {},
  sort: {},
  initMessagesLength: 50,
});

export const module: Module<ITableState, IRootState> = {
  namespaced: true,
  state: (): ITableState => ({
    filters: defaultFilters(),
    sectionCells: {} as ISection,
    infiniteId: null,
    section: Sections.PASSPORT_ARCHIVE_REPORT_VIEW,
    totalLength: 0,
    cells: [],
    searchTemplates: [],
    data: [],
    isOpenFilters: false,
    libraries: {},
    isTableLoading: false,
  }),
  mutations: {
    ...mutations,
    addData(
      state: ITableState,
      payload: {
        data: IReportElement[];
        totalLength: number;
      }
    ) {
      state.data = state.data.concat(payload.data);
      state.totalLength = payload.totalLength;
    },
    clearEventList(state: ITableState) {
      state.data = [];
      state.totalLength = 0;
    },
    setTableLoading(state: ITableState, payload: boolean) {
      state.isTableLoading = payload;
    },
  },
  actions: {
    ...actions,
    clearEventList({ commit }) {
      commit("clearEventList");
    },
    async getArchiveAutocompleteByCode(info, params: string) {
      const { data } = await getQuery<IAutocompleteElement[]>(
        QUERY_PATH.GET_ARCHIVE_AUTOCOMPLETE_ITEMS,
        {
          keyword: params,
        },
        false
      );
      if (data !== null) {
        return data.map((item: IAutocompleteElement) => ({
          ...item,
          titleValue: item.isSearchCode ? item.code : item.name,
        }));
      }
      return [];
    },
    async getEventList({ commit, state, rootGetters }) {
      if (rootGetters["auth/isShowAnimation"]) {
        commit("setTableLoading", true);
      }
      const params = convertFiltersToApi(state.filters as ITableFiltersObject, state.data.length, convertFiltersCustomToApi);
      const {
        data,
        total: totalLength,
        error,
      } = await getQuery<IReportElement[]>(QUERY_PATH.GET_ARCHIVE_SHORT_PASSPORT_PREVIEW, params).finally(() => {
        commit("setTableLoading", false);
      });

      if (data !== null) {
        commit("addData", { data: data || [], totalLength: totalLength || data?.length || 0 });
        return { data: state.data || [], totalLength: totalLength || data?.length || 0 };
      }
      return { data: null, error };
    },
    async getExportData({ state, commit }, payload: "PDF" | "DOCX") {
      const params: IConvertFiltersToApiResponse = convertFiltersToApi(
        state.filters as ITableFiltersObject,
        state.data.length,
        convertFiltersCustomToApi,
        true
      );
      params.cells = state.filters.cells || [];
      delete params.sort;
      const query = payload === "PDF" ? QUERY_PATH.GET_ARCHIVE_SHORT_PASSPORT_PREVIEW_PDF : QUERY_PATH.GET_ARCHIVE_SHORT_PASSPORT_PREVIEW_DOCX;
      commit("app/setIsExportFileLoading", true, {
        root: true,
      });
      const { data } = await getQuery<void>(query, params);
      if (!data) {
        commit("app/setIsExportFileLoading", false, {
          root: true,
        });
      }
    },
  },
  getters: {
    ...getters,
    isTableLoading(state: ITableState) {
      return state.isTableLoading;
    },
    cells() {
      return [];
    },
  },
};
